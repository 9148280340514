import {
  Avatar,
  Box,
  Center,
  ChakraProvider,
  Container,
  extendTheme,
  Heading,
  Hide,
  HStack,
  Image,
  Img,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  StackDirection,
  Text,
  useBreakpointValue,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import Color from "color";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import React, { useCallback, useState } from "react";
import dietsImg from "./diets.svg";
import { Logo } from "./Logo";
import logoImg from "./logo.svg";
const appMain = require("./app_1.png");
const appBuilder = require("./app-builder.png");
const appPairings = require("./app-pairings.png");
const appDetails = require("./app-details.png");
const beansImg = require("./beans.jpg");
const mozzImg = require("./mozz.jpg");
const tomatoImg = require("./tomato.jpg");
const basilImg = require("./basil.jpg");

export default function useSearchParamsState<T extends string>(
  storageKey: string,
  defaultValue: T
) {
  const [state, setState] = useState<T>(() => {
    const url = new URL(window.location.href);
    const storedState = url.searchParams.get(storageKey) as T | null;
    return storedState ?? defaultValue;
  });

  const setAndPersistState = useCallback(
    (newState: T) => {
      const url = new URL(window.location.href);
      url.searchParams.set(storageKey, newState);
      window.history.replaceState({}, "", url.href);
      setState(newState);
    },
    [storageKey]
  );

  return [state, setAndPersistState] as const;
}

const firebaseConfig = {
  apiKey: "AIzaSyDnee27dmLo78qWiWtOZ9f-kLKn2ZIx1cU",
  authDomain: "the-sous-chef-juwar.firebaseapp.com",
  projectId: "the-sous-chef-juwar",
  storageBucket: "the-sous-chef-juwar.appspot.com",
  messagingSenderId: "5724859418",
  appId: "1:5724859418:web:f51082b2e9dddc76a9b5a3",
  measurementId: "G-VHEJ49DBLK",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const TopSection = () => {
  const maxHeight = useBreakpointValue({
    base: "600px",
    "2xl": "900px",
  });
  const top = useBreakpointValue({
    base: "20%",
    "2xl": 0,
  });
  const direction = useBreakpointValue<StackDirection>({
    md: "column",
    lg: "row",
  });
  return (
    <HStack
      height={"950px"}
      width={"full"}
      bgGradient={"linear(to-b, tertiary.100, tertiary.500)"}
      paddingY={8}
      position={"relative"}
      justifyContent={"space-between"}
    >
      <VStack flex={1} paddingX={"10%"} height={"100%"}>
        <HStack flex={1} spacing={4} width={"full"} paddingBottom={20}>
          <Logo pointerEvents={"none"} height={"64px"} src={logoImg} />
          <Heading>The Sous Chef</Heading>
        </HStack>
        <Text
          flex={1}
          fontSize={"xx-large"}
          fontFamily={"snippet"}
          color={"onbackground"}
        >
          Unleash Your Kitchen's Untapped Potential with Sous Chef
        </Text>
        <Text flex={1} fontSize={"x-large"} color={"onbackground"}>
          Join a thriving community of home cooks creating masterpieces with
          their everyday ingredients. With Sous Chef, delightful culinary
          experiences are just a tap away.
        </Text>
        <Stack
          alignItems={"flex-start"}
          width={"full"}
          paddingTop={"70px"}
          direction={direction}
        >
          <a
            href="https://play.google.com/store/apps/details?id=com.awesomeness.souschef&utm_source=reddit&utm_campaign=landing-page&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            style={{
              display: "inline-block",
              overflow: "hidden",
              borderRadius: "13px",
              height: "83px",
            }}
          >
            <Img
              rounded={"full"}
              objectFit={"contain"}
              alt="Get it on Google Play"
              borderRadius={"13px"}
              height={"83px"}
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            />
          </a>
          <a
            href="https://apps.apple.com/us/app/the-sous-chef-recipes-wine/id6473181534?itsct=apps_box_link&itscg=30200"
            style={{
              display: "inline-block",
              overflow: "hidden",
              borderRadius: "13px",
              height: "83px",
            }}
          >
            {/* TODO: Get real link from here when deployed: https://tools.applemediaservices.com/app-store/ */}
            <Img
              rounded={"full"}
              objectFit={"contain"}
              borderRadius={"13px"}
              height={"83px"}
              padding={"10px"}
              alt="Get it on the App Store"
              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1456272000"
            />
          </a>

          {/* <a href="" style="display: inline-block; overflow: hidden; border-radius: 13px; width: 250px; height: 83px;"><img src="" 
           style="border-radius: 13px; width: 250px; height: 83px;"></a> */}
        </Stack>
      </VStack>

      {/* Spacer for image */}
      <Hide below={"md"}>
        <Stack flex={1} />
        <Image
          maxHeight={maxHeight}
          position={"absolute"}
          right={-50}
          top={top}
          src={appMain}
          objectFit={"contain"}
          transformOrigin={"left top"}
          transform={"rotate(30deg) "}
        />
      </Hide>
    </HStack>
  );
};

interface VerticalSectionProps {
  title: string;
  content: string;
  icon: React.ReactNode;
}

const VerticalSection: React.FC<VerticalSectionProps> = ({
  title,
  content,
  icon,
}) => {
  return (
    <VStack flex={1}>
      <Center paddingBottom={8}>{icon}</Center>
      <Text color={"onsecondarycontainer"} as={"b"} fontSize={"large"}>
        {title}
      </Text>
      <Text color={"onsecondarycontainer"}>{content}</Text>
    </VStack>
  );
};

const TriSection = () => {
  const direction = useBreakpointValue<StackDirection>({
    lg: "column",
    xl: "row",
  });
  return (
    <Box paddingX={"60px"} paddingBottom={"60px"}>
      <VStack
        rounded={"32px"}
        background={"tertiarycontainer"}
        padding={"74px"}
      >
        <Center paddingBottom={8}>
          <Text
            fontSize={"xx-large"}
            fontFamily={"snippet"}
            color={"onsecondarycontainer"}
          >
            Experience the future of home cooking with The Sous Chef
          </Text>
        </Center>
        <Stack spacing={"15%"} direction={direction}>
          <VerticalSection
            title={"Discover Your Inner Chef"}
            content={
              "Every person holds the potential to create culinary masterpieces.The Sous Chef is the key to unlocking your inner chef and transforming the way you view your kitchen."
            }
            icon={
              <VStack>
                <Avatar size="2xl" name="MOZZ" src={mozzImg} />
                <HStack>
                  <Avatar size="2xl" name="TOMATO" src={tomatoImg} />
                  <Avatar size="2xl" name="BASIL" src={basilImg} />
                </HStack>
              </VStack>
            }
          />
          <VerticalSection
            title={"A World of Unique Flavors"}
            content={
              "Broaden your culinary horizons with our AI-powered app. Discover new recipes, explore exotic flavours, and create dishes that thrill your taste buds."
            }
            icon={<Avatar size="2xl" name="BEAN" src={beansImg} />}
          />
          <VerticalSection
            title={"Designed for Your Lifestyle"}
            content={
              "Whether you're vegan or vegetarian, The Sous Chef has you covered opening up a world of flavours uniquely crafted for you."
            }
            icon={
              <Logo
                pointerEvents={"none"}
                height={"100%"}
                width={"100%"}
                src={dietsImg}
                color={"onsecondarycontainer"}
              />
            }
          />
        </Stack>
      </VStack>
    </Box>
  );
};

interface GradientBackgroundProps {
  reversed?: boolean;
}

const GradientBackground: React.FC<GradientBackgroundProps> = ({
  reversed,
}) => {
  const theme = useTheme();
  const points = reversed
    ? "10,600 690,10 690,890 200,890"
    : "680,600 10,10 10,890 490,890";
  return (
    <svg
      preserveAspectRatio="none"
      viewBox="0 0 700 900"
      style={{
        width: "100%",
        position: "absolute",
        height: "100%",
        bottom: 0,
        right: reversed ? 0 : undefined,
        left: reversed ? undefined : 0,
      }}
    >
      <defs>
        <linearGradient id="Gradient1" x1="0" x2="0" y1="0" y2="1">
          <stop stop-color={theme.colors.secondary[100]} offset="0%" />
          <stop stop-color={theme.colors.secondary[500]} offset="100%" />
        </linearGradient>
      </defs>
      <polygon
        points={points}
        fill="url(#Gradient1)"
        stroke="black"
        stroke-width="1"
      />
      <polygon
        points={points}
        fill="none"
        stroke-linejoin="round"
        stroke="url(#Gradient1)"
        stroke-width="15"
      />
    </svg>
  );
};

interface ImgSnippetProps {
  title: string;
  content: string;
  img: any;
  reversed?: boolean;
}

const ImgSnippet: React.FC<ImgSnippetProps> = ({
  title,
  content,
  img,
  reversed,
}) => {
  return (
    <HStack
      flexDirection={reversed ? "row-reverse" : "row"}
      height={"900px"}
      width={"full"}
    >
      <Box flex={1} position={"relative"} height={"full"}>
        <GradientBackground reversed={reversed} />
        <Container
          position={"absolute"}
          bottom={0}
          right={reversed ? undefined : 0}
          left={reversed ? 0 : undefined}
          padding={0}
        >
          <Image
            src={img}
            right={reversed ? undefined : 0}
            left={reversed ? 0 : undefined}
          />
        </Container>
      </Box>
      <VStack flex={1} spacing={"150px"} paddingX={"10%"}>
        <Text fontFamily={"snippet"} fontSize={"xx-large"}>
          {title}
        </Text>
        <Text>{content}</Text>
      </VStack>
    </HStack>
  );
};

type HuesMap = {
  ["50"]: string;
  ["100"]: string;
  ["200"]: string;
  ["300"]: string;
  ["400"]: string;
  ["500"]: string;
  ["600"]: string;
  ["700"]: string;
  ["800"]: string;
  ["900"]: string;
};

export function generateMaterialHues(baseHue: string): HuesMap {
  const baseColor = new Color(baseHue);

  const materialHues = {
    "50": baseColor.lighten(0.9).hex(),
    "100": baseColor.lighten(0.6).hex(),
    "200": baseColor.lighten(0.4).hex(),
    "300": baseColor.lighten(0.2).hex(),
    "400": baseColor.lighten(0.1).hex(),
    "500": baseColor.hex(),
    "600": baseColor.darken(0.1).hex(),
    "700": baseColor.darken(0.2).hex(),
    "800": baseColor.darken(0.4).hex(),
    "900": baseColor.darken(0.6).hex(),
  };

  return materialHues;
}

const chefTheme = extendTheme({
  colors: {
    primary: generateMaterialHues("#924c00"),
    secondary: generateMaterialHues("#ff5543"),
    tertiary: generateMaterialHues("#97f0ff"),

    lightText: "#3f0018",
    darkText: "#ffd9e0",
    surfacevariant: "#f3dfd2",
    onprimary: "#ffffff",
    onsecondary: "#ffffff",
    secondarycontainer: "#97f0ff",
    onsecondarycontainer: "#001f24",
    ontertiary: "#ffffff",
    tertiarycontainer: "#ffdad5",
    ontertiarycontainer: "#410000",
    error: "#ba1a1a",
    background: "#fffbff",
    onbackground: "#3f0018",
    surface: "#fffbff",
    onsurface: "#3f0018",
    onsurfacevariant: "#51443b",
    outline: "#84746a",
    outlinevariant: "#d6c3b7",
    surfaceContainerLow: "#ffede3",
    surfaceContainer: "#ffede3",
    surfaceContainerHigh: "#f3dfd2",
  },
});

interface ModalProperties {
  title: string;
  content: string;
}

const ModalPropertiesMap: Record<string, ModalProperties> = {
  "delete-account": {
    title: "How to delete your account",
    content:
      "Within the app, go to My Profile. On this page, sign in to your account. A Delete Account option will then appear. Click this, and confirm you want to delete your account.",
  },
};

export const App = () => {
  const [modalId, setModalId] = useSearchParamsState<"" | "delete-account">(
    "modal-id",
    ""
  );

  return (
    <ChakraProvider theme={chefTheme}>
      <VStack spacing={"100px"} background={"background"}>
        <TopSection />
        <TriSection />
        <ImgSnippet
          title={"Unleash Flavor Combinations"}
          content={
            "Venture beyond the ordinary with our app’s unique ability to find complementary ingredient pairings. Discover culinary harmony with new and unexpected combinations, enhancing your meals in ways you never imagined."
          }
          img={appPairings}
        />
        <ImgSnippet
          title={"Culinary Creation, AI-Powered"}
          content={
            "Ditch the mundane meal plans. With our AI-driven recipe creation, you're only a tap away from a dish tailored to your tastes and preferences. Let's cook outside the box!"
          }
          img={appBuilder}
          reversed
        />
        <ImgSnippet
          title={"Wine Pairing Simplified"}
          content={
            "Pairing the perfect wine is no longer a sommelier's job. With our easy wine pairing feature, find the ideal match for your meal, and take your dining experience to the next level."
          }
          img={appDetails}
        />
        {/* 
      TODO: This isn't finished yet, WIP
      <ImgSnippet
        title={"Teamwork for Culinary Success"}
        content={
          "Cooking is more fun when we do it together! Collaborate with your friends, family, or fellow food lovers within our community. Share, learn, and grow in your culinary journey."
        }
        img={"TODO"}
        reversed
      />
       */}

        <SimpleGrid height={"80px"} columns={3} spacing={10}>
          <Link
            onClick={function howDelete() {
              setModalId("delete-account");
            }}
          >
            How to delete your account
          </Link>
          <Text>Copyright © 2023 PoodlePop LLC.</Text>
          <Link href="./tos.htm">Terms of Service</Link>
          <Box />
          <Text>Built with Chakra.</Text>
          <Link href={"./pp.htm"}>Privacy Policy</Link>
        </SimpleGrid>
      </VStack>

      <Modal
        isOpen={modalId !== ""}
        onClose={function close() {
          setModalId("");
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {modalId && ModalPropertiesMap[modalId].title}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{modalId && ModalPropertiesMap[modalId].content}</Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};
